const epubViewStyles = {
  viewHolder: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  view: {
    height: '100%'
  }
}

export default epubViewStyles
