import React, { Component } from 'react'
import { createGlobalStyle } from 'styled-components'
import FileReaderInput from 'react-file-reader-input'
import { ReactReader } from './modules'
import {
  Container,
  ReaderContainer,
  Bar,
  LogoWrapper,
  Logo,
  GenericButton,
  CloseIcon,
  FontSizeButton,
  ButtonWrapper
} from './Components'

const storage = global.localStorage || null

const DEMO_URL = '/files/Chubby-Chimpers-Chronicles.epub'
const DEMO_NAME = 'Chubby Chimpers Chronicles'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    color: 'dimgray';
    font-size: inherit;
    font-weight: 300;
    line-height: 1.4;
    word-break: break-word;
  }
  html {
    font-size: 62.5%;
  }
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-size: 1.8rem;
    background: #333;
    position: absolute;
    height: 100%;
    width: 100%;
    color: 'dimgray';
  }
`

class App extends Component {
  constructor(props) {
    super(props)
    
    const params = new URLSearchParams(window.location.search);
    const location = params.get("location");

    this.state = {
      fullscreen: false,
      location:
        location 
          ? location
          : storage && storage.getItem('epub-location')
            ? storage.getItem('epub-location')
            : 2,
      localFile: null,
      localName: null,
      largeText: 1
    }
    this.rendition = null
  }

  toggleFullscreen = () => {
    this.setState(
      {
        fullscreen: !this.state.fullscreen
      },
      () => {
        setTimeout(() => {
          const evt = document.createEvent('UIEvents')
          evt.initUIEvent('resize', true, false, global, 0)
        }, 1000)
      }
    )
  }

  setPage = () => {
    return;
    if (this.rendition?.location && this.toc) {
      const { displayed, href } = this.rendition.location.start
      const chapter = this.toc.find(item => item.href === href)
      this.setState({
        page: `Page ${displayed.page} of ${displayed.total} in ${
          chapter ? chapter.label : 'n/a'
        }`
      })
    }
  }

  onLocationChanged = location => {
    this.setState(
      {
        location
      },
      () => {
        storage && storage.setItem('epub-location', location)
      }
    )
    this.setPage()
  }

  onToggleFontSize = () => {
    let nextState = this.state.largeText + 1
    if (nextState === 3) {
      nextState = 0
    }
    this.setState(
      {
        largeText: nextState
      },
      () => {
        this.rendition.themes.fontSize(
          nextState === 2 ? '140%' : nextState === 1 ? '100%' : '80%'
        )
      }
    )
  }

  getRendition = rendition => {
    // Set inital font-size, and add a pointer to rendition for later updates
    const { largeText } = this.state
    this.rendition = rendition
    rendition.themes.fontSize(
      largeText === 2 ? '140%' : largeText === 1 ? '100%' : '80%'
    )
  }
  handleChangeFile = (event, results) => {
    if (results.length > 0) {
      const [e, file] = results[0]
      if (file.type !== 'application/epub+zip') {
        return alert('Unsupported type')
      }
      this.setState({
        localFile: e.target.result,
        localName: file.name,
        location: null
      })
    }
  }
  render() {
    const { fullscreen, location, localFile, localName } = this.state
    return (
      <Container>
        <GlobalStyle />
        <ReaderContainer fullscreen={fullscreen}>
          <ReactReader
            url={localFile || DEMO_URL}
            title={localName || DEMO_NAME}
            location={location}
            locationChanged={this.onLocationChanged}
            getRendition={this.getRendition}
            tocChanged={toc => (this.toc = toc)}
            rendered={() => setTimeout(this.setPage, 1000)}
          />
          <FontSizeButton onClick={this.onToggleFontSize}>
            Toggle font-size
          </FontSizeButton>
          <div
            style={{
              position: 'absolute',
              bottom: '2.5rem',
              right: '1rem',
              left: '1rem',
              textAlign: 'center',
              zIndex: 1,
              color: 'dimgray'
            }}
          >
            {this.state.page}
          </div>
        </ReaderContainer>
      </Container>
    )
  }
}

export default App
